<template>
  <el-dialog class="js-dialog-form" :title="isModify?'修改车牌':'添加车牌'" :visible.sync="dialogVisible" :close-on-click-modal="false" width="560px">
    <ul>
      <!-- <li class="label common-tip"><i>*</i>车牌号码</li> -->
      <li>
        <div class="label common-tip"><i>*</i>车牌号码</div>
        <el-input v-model="params.licensePlate" placeholder="请输入……" size="small"></el-input>
      </li>
      <!-- <li class="label">车主姓名</li> -->
      <li>
        <div class="label common-tip"><i>*</i>车主姓名</div>
        <el-input v-model="params.carOwner" placeholder="请输入……" size="small"></el-input>
      </li>
      <!-- <li class="label">车主身份</li> -->
      <li>
        <div class="label common-tip"><i>*</i>车主身份</div>
        <el-input v-model="params.idcard" placeholder="请输入……" size="small"></el-input>
      </li>
      <!-- <li class="label">车辆型号</li> -->
      <li>
        <div class="label common-tip"><i>*</i>车辆型号</div>
        <el-input v-model="params.carModel" placeholder="请输入……" size="small"></el-input>
      </li>
      <!-- <li class="label">车身颜色</li> -->
      <li>
        <div class="label common-tip"><i>*</i>车身颜色</div>
        <el-input v-model="params.carColor" placeholder="请输入……" size="small"></el-input>
      </li>
      <!-- <li class="label"></li> -->
      <li>
        <el-radio-group v-model="params.status">
          <el-radio :label="0">正常</el-radio>
          <el-radio :label="1">警示</el-radio>
        </el-radio-group>
      </li>
    </ul>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleConfirm" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: '',
  props: ['value','isModify','currentItem'],
  data() {
    return {}
  },
  computed: {
    dialogVisible: {
      get(){
        return this.value
      },
      set(newVal){
        this.$emit('input', newVal)
      }
    },
    params(){
      return this.currentItem
    }
  },
  mounted() {
  },
  methods: {
    handleCancel(){
      this.dialogVisible = false
    },
    handleConfirm(){
      this.$emit('handleAdd',this.params)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>