var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "js-common-wrap js-car-reco" },
    [
      _c("div", { staticClass: "js-common-head" }, [
        _c(
          "div",
          [
            _c("el-button", {
              staticClass: "back-btn",
              attrs: {
                icon: "el-icon-arrow-left",
                size: "small",
                type: "primary",
                plain: "",
              },
              on: { click: _vm.$goBack },
            }),
            _c("label", { staticClass: "label" }, [_vm._v("车辆库")]),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.handleImport },
              },
              [_vm._v("批量导入")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: {
                  click: function ($event) {
                    return _vm.add(0, "")
                  },
                },
              },
              [_vm._v("添加车牌")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "js-common-content" },
        [
          _c(
            "el-table",
            {
              staticClass: "js-table",
              attrs: {
                data: _vm.tableData,
                height: "100%",
                "empty-text": "暂无数据",
              },
              on: { "row-click": _vm.handleRowClick },
            },
            [
              _c("el-table-column", { attrs: { prop: "id", label: "ID" } }),
              _c("el-table-column", {
                attrs: { label: "车牌号码", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "carnum" }, [
                          _vm._v(_vm._s(scope.row.licensePlate)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "carOwner", label: "车辆所有人" },
              }),
              _c("el-table-column", {
                attrs: { prop: "idcard", label: "身份", width: "170" },
              }),
              _c("el-table-column", {
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c(
                          "label",
                          { staticClass: "table-is-warning" },
                          [
                            _vm._v(" 是否警示"),
                            _c("el-checkbox", {
                              staticClass: "warning",
                              on: { change: _vm.changeWarning },
                              model: {
                                value: _vm.isWarning,
                                callback: function ($$v) {
                                  _vm.isWarning = $$v
                                },
                                expression: "isWarning",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 0
                          ? _c("span", [_vm._v("正常")])
                          : _vm._e(),
                        scope.row.status == 1
                          ? _c("span", [_vm._v("警示")])
                          : _vm._e(),
                        scope.row.status == 2
                          ? _c("span", [_vm._v("VIP")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "gmtCreated", label: "创建时间", width: "160" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "gmtUpdated",
                  label: "上次修改时间",
                  width: "160",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", {
                          staticClass: "el-icon-edit-outline",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.add(1, scope.row)
                            },
                          },
                        }),
                        _c("i", {
                          staticClass: "el-icon-delete",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.deleteItem(scope.row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("common-pagination", {
        on: { curPageChange: _vm.handleCurPageChange },
        model: {
          value: _vm.pageOptions,
          callback: function ($$v) {
            _vm.pageOptions = $$v
          },
          expression: "pageOptions",
        },
      }),
      _c("car-import", {
        attrs: { id: _vm.currentID },
        on: { uploadSuccess: _vm.uploadSuccess },
        model: {
          value: _vm.carImportDialogVisible,
          callback: function ($$v) {
            _vm.carImportDialogVisible = $$v
          },
          expression: "carImportDialogVisible",
        },
      }),
      _c("carCard-add", {
        attrs: { currentItem: _vm.currentItem, isModify: _vm.isModify },
        on: { handleAdd: _vm.handleAdd },
        model: {
          value: _vm.addDialogVisible,
          callback: function ($$v) {
            _vm.addDialogVisible = $$v
          },
          expression: "addDialogVisible",
        },
      }),
      _c("common-delete", {
        on: { handleDelete: _vm.handleDelete },
        model: {
          value: _vm.deleteDialogVisible,
          callback: function ($$v) {
            _vm.deleteDialogVisible = $$v
          },
          expression: "deleteDialogVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }