var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "js-dialog-form",
      attrs: {
        title: _vm.isModify ? "修改车牌" : "添加车牌",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        width: "560px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("ul", [
        _c(
          "li",
          [
            _c("div", { staticClass: "label common-tip" }, [
              _c("i", [_vm._v("*")]),
              _vm._v("车牌号码"),
            ]),
            _c("el-input", {
              attrs: { placeholder: "请输入……", size: "small" },
              model: {
                value: _vm.params.licensePlate,
                callback: function ($$v) {
                  _vm.$set(_vm.params, "licensePlate", $$v)
                },
                expression: "params.licensePlate",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("div", { staticClass: "label common-tip" }, [
              _c("i", [_vm._v("*")]),
              _vm._v("车主姓名"),
            ]),
            _c("el-input", {
              attrs: { placeholder: "请输入……", size: "small" },
              model: {
                value: _vm.params.carOwner,
                callback: function ($$v) {
                  _vm.$set(_vm.params, "carOwner", $$v)
                },
                expression: "params.carOwner",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("div", { staticClass: "label common-tip" }, [
              _c("i", [_vm._v("*")]),
              _vm._v("车主身份"),
            ]),
            _c("el-input", {
              attrs: { placeholder: "请输入……", size: "small" },
              model: {
                value: _vm.params.idcard,
                callback: function ($$v) {
                  _vm.$set(_vm.params, "idcard", $$v)
                },
                expression: "params.idcard",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("div", { staticClass: "label common-tip" }, [
              _c("i", [_vm._v("*")]),
              _vm._v("车辆型号"),
            ]),
            _c("el-input", {
              attrs: { placeholder: "请输入……", size: "small" },
              model: {
                value: _vm.params.carModel,
                callback: function ($$v) {
                  _vm.$set(_vm.params, "carModel", $$v)
                },
                expression: "params.carModel",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("div", { staticClass: "label common-tip" }, [
              _c("i", [_vm._v("*")]),
              _vm._v("车身颜色"),
            ]),
            _c("el-input", {
              attrs: { placeholder: "请输入……", size: "small" },
              model: {
                value: _vm.params.carColor,
                callback: function ($$v) {
                  _vm.$set(_vm.params, "carColor", $$v)
                },
                expression: "params.carColor",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "el-radio-group",
              {
                model: {
                  value: _vm.params.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.params, "status", $$v)
                  },
                  expression: "params.status",
                },
              },
              [
                _c("el-radio", { attrs: { label: 0 } }, [_vm._v("正常")]),
                _c("el-radio", { attrs: { label: 1 } }, [_vm._v("警示")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleCancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }